body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #121212;
  overflow: hidden;
}

ul {
  padding: 0;
  list-style: none;
}

/* li {
  cursor: pointer;
  transition: color 0.3s ease;
}

li:hover {
  color: #FFD700;
} */
